import React from "react"
import { Main } from "../components/layout"
import Payment from "../components/pages/payment"

const PaymentPage = () => {
	return (
		<Main>
			<Payment />
		</Main>
	)
}

export default PaymentPage
