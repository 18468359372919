import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import * as styles from "./styles.module.scss"
import LeftCardProfile from "../../../components/layout/LeftCardProfile"

const Payment = () => {
	return (
		<Container className="mt-5">
			<Row>
				<Col md={4} className="pb-3">
					<LeftCardProfile />
				</Col>
				<Col md={8}>
					<div className={styles.rightCard1}>
						<div className={styles.account}>
							<h4 className="text-center">Step 4: Payment</h4>
							<p className="text-center">
								The platform is free to use. However, payment models may vary with university and professional team
								partnerships. We will provide further information based off of schools you are partnered with and how
								they choose to process and cover payment. You will have the choose to opt into these university and
								professional team partnerships.
							</p>
						</div>
					</div>
					<div className="text-center mt-3">
						<Button className={styles.leftCardBtn}>Add my payment information</Button>
					</div>
				</Col>
			</Row>
		</Container>
	)
}

export default Payment
